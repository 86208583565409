export function cleanLink(link: string) {
  return (
    link
      .split('?')[0] // remove query
      .split('/')
      .filter((part, index) => {
        if (index === 0 || part) return true
        return false
      })
      .join('/') + '/'
  )
}

export function normalizeWS(s?: string) {
  if (!s) return ''
  const splittedS = s.replace(/\s+/g, ' ').match(/\S+/g)
  return splittedS ? splittedS.join(' ') : ''
}

export function isElementScrollPosHigherThanScreen(element: HTMLDivElement) {
  return (
    typeof window !== 'undefined' &&
    element.getBoundingClientRect().top <= window.innerHeight
  )
}
