import { ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'

export const StyledProgressScrollListContainer = styled.div`
  position: relative;
  & .scrollable-area {
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const StyledDotsContainer = styled.div`
  position: absolute;
  bottom: -24px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  & > div {
    width: 6px;
    height: 6px;
    background-color: ${(props: ThemeProps) => props.theme.mbColors.midGray};
    border-radius: 50%;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 6px;
    }

    &.active {
      background-color: ${(props: ThemeProps) => props.theme.mbColors.pink};
    }
  }
`
