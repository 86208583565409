import { formatCategory, formatArticle } from '../../../api/dataTransform'
import { PATHS } from '../../../api/paths'
import { callApi, getApiPath } from '../../../api/utils'
import {
  ArticleType as ArticleConstType,
  FEATURE_FLAG,
} from '../../../interfaces/constants'
import { CategoryApi, Article, ArticleApi } from '../../../interfaces/types'
import { cleanLink } from '../../../utils/htmlHelper'

export async function fetchArticleRelatedCategories(article: Article) {
  const currentCategory = getArticleCurrentCategory(article)
  if (!currentCategory) return []
  const slug =
    currentCategory.slug ||
    cleanLink(currentCategory.permalink).split('/').pop()

  const res = await callApi(
    getApiPath(PATHS.GET_CATEGORY_DETAIL, {
      slug: slug || '',
    }),
    'get',
  )

  const subCategories: Array<CategoryApi> = Array.isArray(
    res?.data?.sub_categories,
  )
    ? res?.data?.sub_categories
    : []
  return subCategories.slice(0, 3).map(formatCategory)
}
export function getArticleCurrentCategory(article: Article) {
  return Object.keys(article.category).length > 0
    ? article.category
    : article.categories?.sort(
        (a, b) => b.permalink.length - a.permalink.length,
      )[0]
}

// TODO: refactor this
export function getFeedbackUuid() {
  return (
    typeof window !== 'undefined' &&
    window.localStorage &&
    window.localStorage.getItem('hhg_content_feedback_user')
  )
}
export function setFeedbackUuid(uuid: string) {
  return (
    typeof window !== 'undefined' &&
    window.localStorage.setItem('hhg_content_feedback_user', uuid)
  )
}
export function checkInteractionArticle(
  articleId?: string | number | undefined,
  isLike?: boolean,
) {
  if (articleId && typeof window !== 'undefined' && window.localStorage) {
    const uuid = getFeedbackUuid()
    if (uuid) {
      const articles = window.localStorage.getItem(
        isLike
          ? 'hhg_content_feedback_user-liked'
          : 'hhg_content_feedback_user-disliked',
      )
      return articles ? articles.includes(`${uuid}:::${articleId}`) : false
    }
    return false
  }
  return false
}
export function setInteractionArticle(
  articleId: string | number,
  isLike?: boolean,
) {
  if (typeof window !== 'undefined') {
    const uuid = getFeedbackUuid()
    if (uuid) {
      const articleListString = isLike
        ? 'hhg_content_feedback_user-liked'
        : 'hhg_content_feedback_user-disliked'
      const articles = window.localStorage.getItem(articleListString)
      return window.localStorage.setItem(
        articleListString,
        articles
          ? `${articles},${uuid}:::${articleId}`
          : `${uuid}:::${articleId}`,
      )
    }
    return false
  }
  return false
}

export async function getNextArticle({
  article,
  skipIds = '',
  isRelated = '',
}: {
  article: Article
  skipIds?: string
  isRelated?: string | number
}) {
  const slug = article?.nextArticle?.permalink
  return getArticleBySlug({
    slug,
    skipIds,
    isRelated,
  })
}
export async function getArticleBySlug({
  slug,
  skipIds = '',
  isRelated = '',
}: {
  slug?: string
  skipIds?: string
  isRelated?: string | number
}): Promise<Article | null> {
  if (!slug) return null
  const newApiRes = await callApi(
    getApiPath(PATHS.GET_ARTICLE_DETAIL, {
      slug: slug.split('/').filter(Boolean).join('/'),
      skipIds,
      isRelated,
    }),
    'get',
    undefined,
    true,
  )
  const redirectUrl: string = newApiRes?.data?.redirect_url
  if (redirectUrl) {
    return getArticleBySlug({
      slug: redirectUrl,
      skipIds,
      isRelated,
    })
  }
  const newApiData: ArticleApi = newApiRes?.data?.post
  if (!newApiData) return null
  const newRelatedData: Array<ArticleApi> = newApiRes?.data?.related_articles
  const newArticleData = formatArticle(newApiData)
  newArticleData.relatedArticles = newRelatedData?.map(formatArticle)
  newArticleData.nextArticle = newArticleData.relatedArticles?.filter(
    (related) => !skipIds.includes(String(related.id)),
  )[0]
  if (newArticleData.nextArticle?.id) {
    newArticleData.relatedArticles = newArticleData.relatedArticles?.filter(
      (related) => related.id !== newArticleData.nextArticle?.id,
    )
  }

  if (FEATURE_FLAG.articleType === ArticleConstType.ArticleType3) {
    newArticleData.relatedCategories = []
  } else {
    newArticleData.relatedCategories = await fetchArticleRelatedCategories(
      newArticleData,
    )
  }
  return newArticleData
}

export const getArticleCardDisplayAuthor = (
  article: Article,
): {
  name: string
  avatar: string
  permalink: string
  type: 'expert' | 'author' | 'reviewer' | 'fact_check'
} => {
  if (article.reviewed?.expert?.display_name) {
    return {
      name: article.reviewed?.expert.display_name,
      avatar: article.reviewed?.expert.avatar_thumbnail || '/images/avatar.jpg',
      permalink: article.reviewed?.expert.permalink || '',
      type: 'expert',
    }
  }
  if (article.reviewed?.reviewer?.display_name) {
    return {
      name: article.reviewed?.reviewer.display_name,
      avatar:
        article.reviewed?.reviewer.avatar_thumbnail || '/images/avatar.jpg',
      permalink: article.reviewed?.reviewer.permalink || '',
      type: 'reviewer',
    }
  }
  if (article.reviewed?.author?.display_name) {
    return {
      name: article.reviewed?.author.display_name,
      avatar: article.reviewed?.author.avatar_thumbnail || '/images/avatar.jpg',
      permalink: article.reviewed?.author.permalink || '',
      type: 'author',
    }
  }
  if (article.reviewed?.fact_check?.display_name) {
    return {
      name: article.reviewed.fact_check.display_name,
      avatar: article.reviewed.fact_check.avatar,
      permalink: article.reviewed.fact_check.permalink ?? '',
      type: 'fact_check',
    }
  }
  return {
    name: '',
    avatar: '/images/avatar.jpg',
    permalink: '',
    type: 'author',
  }
}

export const isPreviewSlug = () => {
  return (
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/preview')
  )
}
