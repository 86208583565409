import { MediaQueries, ThemeProps } from '@hhgtech/hhg-components'
import styled from 'styled-components'
import { deviceLayout } from 'styles/breakpoint'

export const StyledHomeWrapper = styled.div`
  overflow: visible;

  min-height: 100vh;
  .banner {
    background-color: ${(props: ThemeProps) => props.theme.colors.gray100};
  }

  #div-gpt-ad-home-top,
  #div-gpt-ad-home-sidebar1,
  #div-gpt-ad-home-sidebar2 {
    max-width: 100%;
    margin: 1rem 0;
    div {
      display: flex;
      overflow: hidden;
      max-width: 100%;
      justify-content: center;
    }
  }

  .homepage-weekofpregnancy {
    ${MediaQueries.mbUp} {
      &:before {
        border-radius: 0;
      }
    }
  }
  .mbUp {
    ${MediaQueries.mbUp} {
      display: none;
    }
  }

  .mbDown {
    ${MediaQueries.mbDown} {
      display: none;
    }
  }
`

export const StyledHomeTrendingContainer = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  .trending-tab {
    position: sticky;
    z-index: 2;
    top: 0;
    width: 100%;
    border-bottom: none;

    ${deviceLayout.pcLayout`
      background-color: ${(props: ThemeProps) => props.theme.colors.white};
      padding: 8px 0 18px;
    `}

    ${deviceLayout.spLayout`
      background-color: ${(props: ThemeProps) =>
        props.theme.mbColors.lightPink};
        padding: 10px 0;
    `}

    & ul {
      ${deviceLayout.spLayout`
        display: flex;
        justify-content: space-around;
        & > li:not(:last-child) {
          margin-right: 0;
        }
        `}
    }

    & > div {
      ${deviceLayout.pcLayout`
        padding: 0 16px;
      `}
    }
  }

  &[data-is-top-bar-appears='true'] {
    .trending-tab {
      top: 64px;
      transition: top 0.5s;
    }
  }

  &[data-is-top-bar-appears='false'] {
    .trending-tab {
      top: 0;
      transition: top 0.5s;
    }
  }
`

export const StyledArticlesContainer = styled.div`
  margin-top: 0px;

  .label {
    color: #2d87f3;
  }

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`

export const StyledArticleContainer = styled.div`
  padding: 24px 16px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  .banner {
    width: 100%;
    min-height: 192px;
  }
`

export const StyledSmallArticleContainer = styled.div`
  padding: 16px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

export const StyleRecommendedTopics = styled.div`
  padding: 40px 16px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};
`

export const StyledScrollListContainer = styled.div`
  padding: 16px 0;

  .bullet-nav {
    bottom: -24px;
  }
`

export const StyledAllTopicsHeading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  & > :first-child {
    margin-right: 4px;
  }
`

export const StyleFeaturedTopics = styled.div`
  padding: 0;

  > :first-child {
    padding: 40px 16px 0;
    background-color: ${(props: ThemeProps) => props.theme.colors.white};
  }

  > div > :not(:first-child) {
    margin-top: 8px;
  }

  .label {
    color: #2d87f3;
  }
`

export const StyledFeatureSmallArticleContainer = styled.div`
  padding: 16px 16px;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  .label {
    color: #2d87f3;
  }
`

export const StyledFeatureTopicSeeMore = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  background-color: ${(props: ThemeProps) => props.theme.colors.white};

  & > :first-child {
    margin-right: 4px;
  }
`

export const StyledHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;

  & > :last-child > h5 {
    display: flex;
    align-items: center;

    & > {
      margin-left: 8px;
    }
  }
`

export const StyledToolsCard = styled.div`
  display: flex;
  min-width: 100%;
  max-width: 400px;
  height: 100%;
  flex-direction: column;
  padding: 0 24px;
  border-radius: 6px;

  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.04)),
    drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.06)),
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
`

export const StyledExpertContainer = styled.div`
  ${MediaQueries.mbUp} {
    padding: 0 16px;
  }
  .description {
    ${MediaQueries.mbDown} {
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
  .see-all {
    text-decoration: none;
    .chevron-right-icon {
      width: 18px;
      height: 18px;

      vertical-align: sub;
    }
  }
  .grid-display {
    display: flex;
    margin-top: 8px;

    .expert-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      > div {
        width: calc(50% - 32px);
        margin-right: 32px;
      }
    }

    .info-area {
      width: 100%;
      max-width: 367px;
      min-height: 264px;
      padding: 24px;
      background-color: ${(props: ThemeProps) => props.theme.colors.neutral50};

      border-radius: 6px;
    }
  }
`

export const StyledSeeAllExpert = styled.a`
  display: block;
  width: 100%;
  margin-top: 16px;
  text-decoration: none;
  button {
    width: 100%;
  }
`
