import React, { useState, useRef } from 'react'

import {
  StyledDotsContainer,
  StyledProgressScrollListContainer,
} from './index.styled'

type Props = {
  children: JSX.Element | JSX.Element[]
  numberOfDots: number
  className?: string
}

const ProgressScrollList = ({ children, numberOfDots, className }: Props) => {
  const [activeDot, setActiveDot] = useState<number>(0)
  const scrollRef = useRef<HTMLDivElement>(null)
  const trackRef = useRef<HTMLDivElement>(null)

  let timer: ReturnType<typeof setTimeout>

  const detectActiveDot = (area: number, scrollLeft: number, dots: number) => {
    if (scrollLeft === 0) return 0
    const x = (scrollLeft / area) * dots
    return (
      Array.from({ length: dots + 1 }, (_, i) => i)
        .concat(x)
        .sort()
        .indexOf(x) - 1
    )
  }
  const onScroll = (event: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setActiveDot(
        detectActiveDot(
          (trackRef.current?.clientWidth ?? 0) -
            (scrollRef.current?.clientWidth ?? 0),
          event.target?.scrollLeft ?? 0,
          numberOfDots,
        ),
      )
    }, 50)
  }

  const onDotClick = (i: number) => {
    if (scrollRef.current) {
      if (i === 0) {
        scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' })
      } else if (i === numberOfDots - 1) {
        scrollRef.current.scrollTo({
          left:
            (trackRef.current?.clientWidth ?? 0) -
            (scrollRef.current?.clientWidth ?? 0),
          behavior: 'smooth',
        })
      } else {
        const r =
          (((trackRef.current?.clientWidth ?? 0) -
            (scrollRef.current?.clientWidth ?? 0)) /
            numberOfDots) *
          (i + 0.5)
        scrollRef.current.scrollTo({ left: r, behavior: 'smooth' })
      }
    }
  }

  return (
    <StyledProgressScrollListContainer className={className}>
      <div className="scrollable-area" ref={scrollRef} onScroll={onScroll}>
        <div className="scroll-track" ref={trackRef}>
          {children}
        </div>
      </div>
      {!!numberOfDots && (
        <StyledDotsContainer>
          {Array.from({ length: numberOfDots }, (_, i) => (
            <div
              onClick={() => onDotClick(i)}
              key={i}
              className={activeDot === i ? 'active' : undefined}
            />
          ))}
        </StyledDotsContainer>
      )}
    </StyledProgressScrollListContainer>
  )
}

export { ProgressScrollList }
